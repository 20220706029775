<template>
    <div>
        <dmx-title :bc-items="bcItems">{{ $t('subscription_title') }}</dmx-title>

        <b-row align-v="center" class="mb-1">
            <b-col lg="2" sm="3">
                {{ subscriptionList.length }} {{ $t('subscription_subscriptions_found') }}
                <!--     <span class="ml-3">
                    Show: <b-link @click="setPerPage(10)"
                        :style="((options.perPage == 10) ? 'font-weight: bold;' : ' ')">10</b-link> |
                    <b-link @click="setPerPage(20)"
                        :style="((options.perPage == 20) ? 'font-weight: bold;' : ' ')">20</b-link> |
                    <b-link @click="setPerPage(1000)"
                        :style="((options.perPage == 1000) ? 'font-weight: bold;' : ' ')">All</b-link>
                </span> -->
            </b-col>
            <!-- Search -->
            <b-col lg="2" sm="3">
                <b-input v-model="filterAllSubscription.searchTerm" @input="searchWithText()"
                    :loading="searchFieldIsLoading" :placeholder="$t('common_search')">
                </b-input>
            </b-col>
            <!-- Filters -->
            <b-col lg="2" sm="3">
                <b-select v-model="filterAllSubscription.paymentStatus" @change="searchWithText()"
                    :options="paymentStatus"></b-select>
            </b-col>
            <b-col lg="2" sm="3">
                <b-select v-model="filterAllSubscription.paymentMethod" @change="searchWithText()"
                    :options="paymentMethod"></b-select>
            </b-col>
            <b-col lg="2" sm="3">
                <b-select v-model="filterAllSubscription.ownerEmail" @change="searchWithText()"
                    :options="getAllOwnerEmails"></b-select>
            </b-col>
            <b-col lg="2" sm="3" align="right">
                <b-dropdown id="dropdown-right" dropleft text="Functions" variant="primary" class="m-2">
                    <b-dropdown-item href="#" :disabled="(selected.length > 0 ? false : true)"
                        v-b-modal.sum-sub-modal>Print subscription list</b-dropdown-item>
                    <b-dropdown-item href="#" :disabled="true">Cancel
                        subscriptions</b-dropdown-item>
                    <b-dropdown-divider></b-dropdown-divider>
                    <b-dropdown-item href="#" :disabled="true">Change payment
                        status</b-dropdown-item>
                </b-dropdown>
            </b-col>
        </b-row>

        <b-table id="allsub_table" style="cursor: default !important;" thead-class="dmx-thead-block"
            tbody-tr-class="dmx-pointer-element" :busy="loading" outlined striped bordered hover
            :items="subscriptionList" :fields="headers" ref="selectableTable" selectable @row-selected="onRowSelected">
            <template #head(selected)="data">
                <span @click="selectAllRows" style="text-decoration: underline;cursor:pointer;">Select
                    all</span>
            </template>
            <template #cell(selected)="{ rowSelected }">
                <template v-if="rowSelected">
                    <span aria-hidden="true">&check;</span>
                    <span class="sr-only">Selected</span>
                </template>
                <template v-else>
                    <span aria-hidden="true">&nbsp;</span>
                    <span class="sr-only">Not selected</span>
                </template>
            </template>
            <template #cell(gateName)="row">

                <span v-if="row.item.gateId > 0" style="text-decoration: underline;cursor:pointer;"
                    @click="viewAttachedGate(row.item.gateId)" class="status-status">{{ row.item.gateName }}</span>
            </template>
            <template #cell(totalAmount)="row">
                <span>
                    {{ row.item.payment.totalAmount }} {{ row.item.payment.currency.currencyCode }}
                </span>

            </template>
            <template #cell(subscriptionExpiryDateUtc)="row">
                <span>
                    {{ utcFormattedWOtime(row.item.subscriptionExpiryDateUtc) }}
                </span>
            </template>
            <template #cell(status)="row">
                <span class="mr-2">
                    <label v-if="row.item.isCurrent && row.item.subscriptionExpiryDateUtc != null"
                        class="badge badge-success">Active</label>
                    <label v-else-if="row.item.isCurrent && row.item.subscriptionExpiryDateUtc === null"
                        class="badge badge-warning">Not started</label>
                    <label v-else-if="!row.item.isCurrent" class="badge badge-warning">Inactive</label>
                </span>
                <span class="mr-2">
                    <label v-if="row.item.paymentStatus == 'Paid'" class="badge badge-success">Paid</label>
                    <label v-else-if="row.item.paymentStatus == 'Pending'" class="badge badge-warning">Pending</label>
                    <label v-else-if="row.item.paymentStatus == 'Cancelled'"
                        class="badge badge-danger">Cancelled</label>
                </span>
                <span v-if="row.item.history" size="sm" @click.stop="row.toggleDetails" class="mr-2">
                    <label class="badge badge-info"><i style="font-weight: bold;cursor: pointer;"
                            class="bi bi-clock-history"></i></label>
                </span>
            </template>
            <template #row-details="row">
                <!-- Subscription history -->
                <b-table id="allsubsub_table" style="cursor: default !important;" thead-class="dmx-thead-block-sub"
                    :busy="loading" :items="row.item.history" :fields="headers">
                </b-table>


                <b-button size="sm" @click="row.toggleDetails">Hide</b-button>

            </template>
        </b-table>
        <!--  <div>
            <b-pagination align="center" v-model="options.currentPage" :total-rows="pagination.totalListCount"
                :per-page="options.perPage" aria-controls="sub-table">
            </b-pagination>
        </div> -->
        <!-- Modal for gate-addnew -->
        <b-modal :no-close-on-backdrop="true" ref="sumsub" hide-footer hide-header size="lg" id="sum-sub-modal"
            title="Summary, selected subscriptions" body-class="p-0">
            <selected-subs :selectedSubscriptions="selected"></selected-subs>
        </b-modal>
    </div>
</template>

<style>
.dmx-thead-block-sub {
    background: #9f9f9f;
    color: #003440;
    font-size: 0.8em;
}
</style>

<script>
import subscriptionService from '@/services/subscriptionService';
import router from '@/router';
import summarySelectedSubsComponent from '@/components/subscriptions/summary-selected-subscriptions.vue';
export default {
    name: "SubscriptionList",
    components: {
        'selected-subs': summarySelectedSubsComponent,
    },
    data: () => ({

        loading: true,

        //flags
        searchFieldIsLoading: false,

        // values
        searchField: '',
        subscriptionList: [],
        selected: [],

        // pagination
        filterTableHeadName: '',
        timeoutId: 0,
        bcItems: [
            {
                text: 'subscriptions',
                href: '/SubscriptionList',
                active: true,
            }
        ],
        filterAllSubscription: {
            searchTerm: '',
            ownerEmail: '',
            paymentMethod: 0,
            paymentStatus: 0
        }
    }),
    computed: {
        headers() {
            return [
                {
                    label: 'Selected all',
                    sortable: false,
                    key: 'selected',
                    width: '2%'
                },
                {
                    label: 'Id',
                    align: 'start',
                    sortable: true,
                    key: 'id',
                },
                {
                    label: 'Gate name',
                    align: 'start',
                    sortable: true,
                    key: 'gateName',
                },
                {
                    label: 'Gate Owner',
                    align: 'start',
                    sortable: true,
                    key: 'gateOwnerEmail'
                },
                {
                    label: 'Payment method',
                    align: 'start',
                    sortable: true,
                    key: 'paymentMethodName',
                },
                {
                    label: 'Total amount',
                    align: 'start',
                    sortable: true,
                    key: 'totalAmount',
                },
                {
                    label: 'Expires',
                    align: 'start',
                    sortable: true,
                    key: 'subscriptionExpiryDateUtc'
                },
                {
                    label: 'Order id',
                    align: 'start',
                    sortable: true,
                    key: 'orderId',
                }, {
                    label: 'Status',
                    align: 'start',
                    sortable: false,
                    key: 'status',
                },
            ]
        },
        paymentStatus() {
            return [
                { value: 0, text: '- Select Status -' },
                { value: 10, text: 'Pending' },
                { value: 20, text: 'Paid' },
                { value: 30, text: 'Cancelled' },
            ]
        },
        paymentMethod() {
            return [
                { value: 0, text: '- Select Method -' },
                { value: 10, text: 'Not billed' },
                { value: 20, text: 'Card' },
                { value: 30, text: 'Invoice' },
            ]
        },
        getAllOwnerEmails() {
            let ownerEmailList = [
                { value: '', text: '- Select Owner -' }
            ]
            this.subscriptionList.forEach(function (sub) {

                if(ownerEmailList.some(x => x.text === sub.gateOwnerEmail)) {
                }else {
                    ownerEmailList.push({ value: sub.gateOwnerEmail, text: sub.gateOwnerEmail });
                }

            });
            return ownerEmailList;
        }
    },
    methods: {
        onRowSelected(items) {
            this.selected = items
        },
        selectAllRows() {
            if (this.subscriptionList.length === this.selected.length) {
                this.clearSelected();
            } else {
                this.$refs.selectableTable.selectAllRows()
            }

        },
        clearSelected() {
            this.$refs.selectableTable.clearSelected()
        },
        viewAttachedGate(e) {
            router.push({ name: 'editGate', params: { gateId: parseInt(e) } })
        },
        searchWithText() {
            this.searchFieldIsLoading = true;
            if (this.timeoutId > 0) {
                clearTimeout(this.timeoutId);
            }
            this.timeoutId = window.setTimeout(() => {
                this.getGateSubscriptionList();
            }, 600);
        },

        filterByTableHeader(tableHeaderName) {
            this.filterTableHeadName = tableHeaderName;
            this.getGateSubscriptionList();
        },
        getGateSubscriptionList() {

            subscriptionService.getGateSubscriptions(this.filterAllSubscription)
                .then((data) => {
                    this.subscriptionList = data;
                    this.loading = false;
                })
                .catch(function (error) {
                    alert(error);
                });
        },
    },
    mounted() {
        this.getGateSubscriptionList();

    }
}
</script>
